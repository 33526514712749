import React from "react";

const WelcomDashboardPage: React.FC = () => {
  return (
    <div className="welcome-dashboard-page h-100 d-flex flex-column align-items-center justify-content-center">
      <img src="/assets/images/logo.png" alt="logo" className="mb-4" />
    </div>
  );
};

export default WelcomDashboardPage;
