const areas = [
  {
    provCode: "101",
    provName: "Thành phố Hà Nội",
  },
  {
    provCode: "103",
    provName: "Thành phố Hải Phòng",
  },
  {
    provCode: "107",
    provName: "Tỉnh Hải Dương",
  },
  {
    provCode: "109",
    provName: "Tỉnh Hưng Yên",
  },
  {
    provCode: "111",
    provName: "Tỉnh Hà Nam",
  },
  {
    provCode: "113",
    provName: "Tỉnh Nam Định",
  },
  {
    provCode: "115",
    provName: "Tỉnh Thái Bình",
  },
  {
    provCode: "117",
    provName: "Tỉnh Ninh Bình",
  },
  {
    provCode: "201",
    provName: "Tỉnh Hà Giang",
  },
  {
    provCode: "203",
    provName: "Tỉnh Cao Bằng",
  },
  {
    provCode: "205",
    provName: "Tỉnh Lào Cai",
  },
  {
    provCode: "207",
    provName: "Tỉnh Bắc Kạn",
  },
  {
    provCode: "209",
    provName: "Tỉnh Lạng Sơn",
  },
  {
    provCode: "211",
    provName: "Tỉnh Tuyên Quang",
  },
  {
    provCode: "213",
    provName: "Tỉnh Yên Bái",
  },
  {
    provCode: "215",
    provName: "Tỉnh Thái Nguyên",
  },
  {
    provCode: "217",
    provName: "Tỉnh Phú Thọ",
  },
  {
    provCode: "219",
    provName: "Tỉnh Vĩnh Phúc",
  },
  {
    provCode: "221",
    provName: "Tỉnh Bắc Giang",
  },
  {
    provCode: "223",
    provName: "Tỉnh Bắc Ninh",
  },
  {
    provCode: "225",
    provName: "Tỉnh Quảng Ninh",
  },
  {
    provCode: "301",
    provName: "Tỉnh Điện Biên",
  },
  {
    provCode: "302",
    provName: "Tỉnh Lai Châu",
  },
  {
    provCode: "303",
    provName: "Tỉnh Sơn La",
  },
  {
    provCode: "305",
    provName: "Tỉnh Hoà Bình",
  },
  {
    provCode: "401",
    provName: "Tỉnh Thanh Hoá",
  },
  {
    provCode: "403",
    provName: "Tỉnh Nghệ An",
  },
  {
    provCode: "405",
    provName: "Tỉnh Hà Tĩnh",
  },
  {
    provCode: "407",
    provName: "Tỉnh Quảng Bình",
  },
  {
    provCode: "409",
    provName: "Tỉnh Quảng Trị",
  },
  {
    provCode: "411",
    provName: "Tỉnh Thừa Thiên Huế",
  },
  {
    provCode: "501",
    provName: "Thành phố Đà Nẵng",
  },
  {
    provCode: "503",
    provName: "Tỉnh Quảng Nam",
  },
  {
    provCode: "505",
    provName: "Tỉnh Quảng Ngãi",
  },
  {
    provCode: "507",
    provName: "Tỉnh Bình Định",
  },
  {
    provCode: "509",
    provName: "Tỉnh Phú Yên",
  },
  {
    provCode: "511",
    provName: "Tỉnh Khánh Hoà",
  },
  {
    provCode: "601",
    provName: "Tỉnh Kon Tum",
  },
  {
    provCode: "603",
    provName: "Tỉnh Gia Lai",
  },
  {
    provCode: "605",
    provName: "Tỉnh Đăk Lăk",
  },
  {
    provCode: "606",
    provName: "Tỉnh Đăk Nông",
  },
  {
    provCode: "701",
    provName: "TP. Hồ Chí Minh",
  },
  {
    provCode: "703",
    provName: "Tỉnh Lâm Đồng",
  },
  {
    provCode: "705",
    provName: "Tỉnh Ninh Thuận",
  },
  {
    provCode: "707",
    provName: "Tỉnh Bình Phước",
  },
  {
    provCode: "709",
    provName: "Tỉnh Tây Ninh",
  },
  {
    provCode: "711",
    provName: "Tỉnh Bình Dương",
  },
  {
    provCode: "713",
    provName: "Tỉnh Đồng Nai",
  },
  {
    provCode: "715",
    provName: "Tỉnh Bình Thuận",
  },
  {
    provCode: "717",
    provName: "Tỉnh Bà Rịa - Vũng Tàu",
  },
  {
    provCode: "801",
    provName: "Tỉnh Long An",
  },
  {
    provCode: "803",
    provName: "Tỉnh Đồng Tháp",
  },
  {
    provCode: "805",
    provName: "Tỉnh An Giang",
  },
  {
    provCode: "807",
    provName: "Tỉnh Tiền Giang",
  },
  {
    provCode: "809",
    provName: "Tỉnh Vĩnh Long",
  },
  {
    provCode: "811",
    provName: "Tỉnh Bến Tre",
  },
  {
    provCode: "813",
    provName: "Tỉnh Kiên Giang",
  },
  {
    provCode: "815",
    provName: "Thành phố Cần Thơ",
  },
  {
    provCode: "816",
    provName: "Tỉnh Hậu Giang",
  },
  {
    provCode: "817",
    provName: "Tỉnh Trà Vinh",
  },
  {
    provCode: "819",
    provName: "Tỉnh Sóc Trăng",
  },
  {
    provCode: "821",
    provName: "Tỉnh Bạc Liêu",
  },
  {
    provCode: "823",
    provName: "Tỉnh Cà Mau",
  },
];

export default areas;
