import React from "react";
import Loader from "./component/commons/Loader";
import Toast from "./component/commons/Toast";
import HistoryRouter, { history } from "./routes/HistoryRouter";
import MyRouter from "./routes/MyRouter";

const App: React.FC = () => {
  return (
    <HistoryRouter history={history}>
      <Loader />
      <Toast />
      <MyRouter />
    </HistoryRouter>
  );
};

export default App;
