import React, { Fragment, useEffect, useState } from "react";
import { ContractorType } from "../../types";
import { contractorList } from "../../data";
import dayjs from "dayjs";

interface Props {
  contractorId: string;
}

const tabList = [
  { title: "Thông tin chung", key: 1 },
  { title: "Hồ sơ mời thầu", key: 2 },
  { title: "Làm rõ HSMT", key: 3 },
  { title: "Hội nghị tiền đấu thầu", key: 4 },
  { title: "Kiến nghị", key: 5 },
];

const ContractorDetail: React.FC<Props> = ({ contractorId }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [contractorDetail, setContractorDetail] = useState<ContractorType | null>(null);

  const calculateTimeToNow = (date: string) => {
    const dateUnixTime = dayjs(date).unix();
    const currentUnixTime = dayjs().unix();
    let duration = dateUnixTime - currentUnixTime; // format is seconds
    if (duration < 0) return "0 ngày 0 giờ 0 phút";
    else {
      let days = duration / 86400;
      days = days > 0 ? Math.floor(duration / 86400) : Math.ceil(duration / 86400);
      duration = days === 0 ? duration : duration % 86400;

      const hours = duration / 3600 > 0 ? Math.floor(duration / 3600) : Math.ceil(duration / 3600);
      duration = hours === 0 ? duration : duration % hours;

      const minutes = duration / 60 > 0 ? Math.floor(duration / 60) : Math.ceil(duration / 60);

      return `${days} ngày ${hours} giờ ${minutes} phút`;
    }
  };

  const handleGetContractorDetail = () => {
    setContractorDetail(contractorList.find((item) => item.bidId === contractorId) || null);
  };

  useEffect(() => {
    handleGetContractorDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="contractor-detail">
      {contractorDetail && (
        <Fragment>
          <div className="card mb-10 bg-light-dark">
            <div className="card-body text-center">
              <p className="text-gray-700 mb-3">Thời điểm đóng thầu</p>
              <p className="fw-bold mb-5" style={{ color: "#be8a4b" }}>
                {dayjs(contractorDetail.bidCloseDate).format("HH:mm")}
              </p>
              <p className="fw-bold fs-1 mb-5" style={{ color: "#0f4871" }}>
                {dayjs(contractorDetail.bidCloseDate).format("DD/MM/YYYY")}
              </p>
              <button className="btn btn-primary w-100 mb-5">Nộp hồ sơ</button>
              <p className="text-danger fw-medium">
                Còn lại: {calculateTimeToNow(contractorDetail.bidCloseDate)}
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center flex-wrap gap-3 mb-10">
            {tabList.map((item) => {
              return (
                <button
                  key={item.key}
                  className={`btn btn-sm rounded-pill py-1 px-3 ${
                    activeTab === item.key ? "btn-primary" : "btn-secondary"
                  }`}
                  onClick={() => setActiveTab(item.key)}
                >
                  {item.title}
                </button>
              );
            })}
          </div>
          {activeTab === 1 ? (
            <div className="contractor-detail__general-info">
              <div className="mb-10">
                <h6 className="contractor-detail__general-info__title bg-secondary bg-opacity-50 p-3 rounded mb-3">
                  Thông tin cơ bản
                </h6>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Mã TBMT</p>
                  <p className="w-66 mb-0 text-gray-700">{contractorDetail.notifyNoStand}</p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Ngày đăng tải</p>
                  <p className="w-66 mb-0 text-gray-700">
                    {dayjs(contractorDetail.publicDate).format("DD/MM/YYYY - HH:mm")}
                  </p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Phiên bản thay đổi</p>
                  <p className="w-66 mb-0 text-gray-700">{contractorDetail.notifyVersion}</p>
                </div>
              </div>
              <div className="mb-10">
                <h6 className="contractor-detail__general-info__title bg-secondary bg-opacity-50 p-3 rounded mb-3">
                  Thông tin chung của KHLCNT
                </h6>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Mã KHLCNT</p>
                  <p className="w-66 mb-0 text-gray-700">{contractorDetail.planNo}</p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Phân loại KHLCNT</p>
                  <p className="w-66 mb-0 text-gray-700">{contractorDetail.planType}</p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Tên dự toán mua sắm</p>
                  <p className="w-66 mb-0 text-gray-700">{contractorDetail.bidName[0]}</p>
                </div>
              </div>
              <div className="mb-10">
                <h6 className="contractor-detail__general-info__title bg-secondary bg-opacity-50 p-3 rounded mb-3">
                  Thông tin gói thầu
                </h6>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Quy trình áp dụng</p>
                  <p className="w-66 mb-0 text-gray-700">{contractorDetail.processApply}</p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Tên gói thầu</p>
                  <p className="w-66 mb-0 text-gray-700">{contractorDetail.bidName[0]}</p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Chủ đầu tư</p>
                  <p className="w-66 mb-0 text-gray-700">{contractorDetail.investorName}</p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Bên mời thầu</p>
                  <p className="w-66 mb-0 text-gray-700">{contractorDetail.procuringEntityName}</p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Chi tiết nguồn vốn</p>
                  <p className="w-66 mb-0 text-gray-700"></p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Lĩnh vực</p>
                  <p className="w-66 mb-0 text-gray-700">
                    {contractorDetail.investField.join(", ")}
                  </p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Hình thức lựa chọn nhà thầu</p>
                  <p className="w-66 mb-0 text-gray-700">{contractorDetail.bidForm}</p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Loại hợp đồng</p>
                  <p className="w-66 mb-0 text-gray-700"></p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Trong nước/ Quốc tế</p>
                  <p className="w-66 mb-0 text-gray-700"></p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Phương thức lựa chọn nhà thầu</p>
                  <p className="w-66 mb-0 text-gray-700"></p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Thời gian thực hiện gói thầu</p>
                  <p className="w-66 mb-0 text-gray-700"></p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Thời gian thực hiện hợp đồng</p>
                  <p className="w-66 mb-0 text-gray-700"></p>
                </div>
              </div>
              <div className="mb-10">
                <h6 className="contractor-detail__general-info__title bg-secondary bg-opacity-50 p-3 rounded mb-3">
                  Cách thức dự thầu
                </h6>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Hình thức dự thầu</p>
                  <p className="w-66 mb-0 text-gray-700">
                    {contractorDetail.isInternet === 1 ? "Qua mạng" : "Không qua mạng"}
                  </p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Địa điểm phát hành e-HSMT</p>
                  <p className="w-66 mb-0 text-gray-700"></p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Chi phí nộp e-HSDT</p>
                  <p className="w-66 mb-0 text-gray-700"></p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Địa điểm nhận e-HSDT</p>
                  <p className="w-66 mb-0 text-gray-700"></p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Địa điểm thực hiện gói thầu</p>
                  <p className="w-66 mb-0 text-gray-700">
                    {contractorDetail.locations && contractorDetail.locations[0].districtName}
                    &nbsp;-&nbsp;
                    {contractorDetail.locations && contractorDetail.locations[0].provName}
                  </p>
                </div>
              </div>
              <div className="mb-10">
                <h6 className="contractor-detail__general-info__title bg-secondary bg-opacity-50 p-3 rounded mb-3">
                  Thông tin dự thầu
                </h6>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Thời điểm đóng thầu</p>
                  <p className="w-66 mb-0 text-gray-700">
                    {dayjs(contractorDetail.bidCloseDate).format("DD/MM/YYYY - HH:mm")}
                  </p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Thời điểm mở thầu</p>
                  <p className="w-66 mb-0 text-gray-700">
                    {dayjs(contractorDetail.bidOpenDate).format("DD/MM/YYYY - HH:mm")}
                  </p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Địa điểm mở thầu</p>
                  <p className="w-66 mb-0 text-gray-700"></p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Hiệu lực hồ sơ dự thầu</p>
                  <p className="w-66 mb-0 text-gray-700"></p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Số tiền đảm bảo dự thầu</p>
                  <p className="w-66 mb-0 text-gray-700"></p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Hình thức đảm bảo dự thầu</p>
                  <p className="w-66 mb-0 text-gray-700"></p>
                </div>
              </div>
              <div className="mb-10">
                <h6 className="contractor-detail__general-info__title bg-secondary bg-opacity-50 p-3 rounded mb-3">
                  Thông tin quyết định phê duyệt
                </h6>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Số quyết định phê duyệt</p>
                  <p className="w-66 mb-0 text-gray-700"></p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Ngày phê duyệt</p>
                  <p className="w-66 mb-0 text-gray-700"></p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Cơ quan ban hành quyết định</p>
                  <p className="w-66 mb-0 text-gray-700"></p>
                </div>
                <div className="d-flex flex-column flex-sm-row py-3 mx-3 border-bottom">
                  <p className="w-33 mb-0 text-dark">Quyết định phê duyệt</p>
                  <p className="w-66 mb-0 text-gray-700"></p>
                </div>
              </div>
            </div>
          ) : activeTab === 2 ? (
            "Hồ sơ mời thầu"
          ) : activeTab === 3 ? (
            "Làm rõ HSMT"
          ) : activeTab === 4 ? (
            "Hội nghị tiền đấu thầu"
          ) : (
            "Kiến nghị"
          )}
        </Fragment>
      )}
    </div>
  );
};

export default ContractorDetail;
