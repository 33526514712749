import React from "react";
import { Modal } from "antd";
import { SubmitHandler, useForm } from "react-hook-form";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

type FormDataType = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const ModalChangePassword: React.FC<Props> = ({ isOpen, onClose }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    clearErrors,
  } = useForm<FormDataType>();

  const onSubmit: SubmitHandler<FormDataType> = async (value) => {
    console.log(value);
  };

  const handleCancel = () => {
    onClose();
    clearErrors();
  };

  return (
    <Modal open={isOpen} footer={null} onCancel={handleCancel} destroyOnClose>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate={true}
        onSubmit={handleSubmit(onSubmit)}
      >
        <h6 className="mb-6 fs-3 text-center">Thay đổi mật khẩu</h6>
        <div className="row">
          <div className="col-12 mb-3">
            <label className="col-form-label required fw-semibold fs-6">Mật khẩu hiện tại</label>
            <div className="col-12 fv-row fv-plugins-icon-container">
              <input
                type="password"
                className="form-control bg-transparent"
                placeholder="Mật khẩu hiện tại"
                {...register("oldPassword", {
                  required: "Vui lòng nhập Mật khẩu hiện tại!",
                })}
              />
              {errors.oldPassword && (
                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                  {errors.oldPassword.message}
                </div>
              )}
            </div>
          </div>
          <div className="col-12 mb-3">
            <label className="col-form-label required fw-semibold fs-6">Mật khẩu mới</label>
            <div className="col-12 fv-row fv-plugins-icon-container">
              <input
                type="password"
                className="form-control bg-transparent"
                placeholder="Mật khẩu mới"
                {...register("newPassword", {
                  required: "Vui lòng nhập Mật khẩu mới!",
                })}
              />
              {errors.newPassword && (
                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                  {errors.newPassword.message}
                </div>
              )}
            </div>
          </div>
          <div className="col-12 mb-3">
            <label className="col-form-label required fw-semibold fs-6">Xác nhận mật khẩu</label>
            <div className="col-12 fv-row fv-plugins-icon-container">
              <input
                type="password"
                className="form-control bg-transparent"
                placeholder="Xác nhận mật khẩu"
                {...register("confirmPassword", {
                  required: "Vui lòng Xác nhận mật khẩu!",
                  validate: (val: string) => {
                    if (watch("newPassword") !== val) {
                      return "Mật khẩu không trùng khớp!";
                    }
                  },
                })}
              />
              {errors.confirmPassword && (
                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                  {errors.confirmPassword.message}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="my-3 text-end">
          <button className="btn btn-secondary me-3" type="button" onClick={handleCancel}>
            Huỷ
          </button>
          <button className="btn btn-primary" type="submit">
            Cập nhật
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalChangePassword;
