import React, { useState } from "react";
import { ContractorType } from "../../types";
import dayjs from "dayjs";
import { Drawer } from "antd";
import ContractorDetail from "./ContractorDetail";
import "./index.scss";

interface Props {
  contractorList: ContractorType[];
}

const ContractorList: React.FC<Props> = ({ contractorList }) => {
  const [contractorIdGetDetail, setContractorIdGetDetail] = useState<ContractorType | null>(null);
  return (
    <div className="contractor-list">
      <div className="card mb-5">
        <div className="card-body">
          {contractorList.map((item, index) => {
            return (
              <div
                key={item.Id}
                className={`card card-dashed ${index !== contractorList.length - 1 ? "mb-5" : ""}`}
              >
                <div className="card-body p-3">
                  <div className="d-flex align-items-center justify-content-between flex-wrap gap-2">
                    <div className="d-flex align-items-center">
                      <button className="btn p-0 me-2">
                        <span>
                          {/* <i className="fa-solid fa-star text-warning fs-2"></i> */}
                          <i className="fa-regular fa-star text-warning fs-2"></i>
                        </span>
                      </button>
                      <span className="text-gray-700 mb-0">Mã TBMT: {item.notifyNoStand}</span>
                    </div>
                    <span className="badge badge-primary badge-lg rounded-pill justify-sefl-end">
                      Chưa đóng thầu
                    </span>
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <h3
                        className="text-primary cursor-pointer fs-3 fw-bold text-truncate-2 my-2"
                        onClick={() => setContractorIdGetDetail(item)}
                      >
                        {item.bidName[0]}
                      </h3>
                      <div className="row">
                        <div className="col-lg-8">
                          <p className="mb-0 fs-5 text-truncate-1">
                            <span className="fw-bold">Chủ đầu tư/ Bên mời thầu:</span>
                            &nbsp;
                            {item.investorName}
                          </p>
                          <p className="mb-0 fs-5">
                            <span className="fw-bold">Ngày đăng tải:</span>
                            &nbsp;
                            {dayjs(item.publicDate).format("DD/MM/YYYY - HH:mm")}
                          </p>
                        </div>
                        <div className="col-lg-4">
                          <p className="mb-0 fs-5">
                            <span className="fw-bold">Lĩnh vực:</span>
                            &nbsp;
                            {item.investField.join(", ")}
                          </p>
                          <p className="mb-0 fs-5 text-truncate-2">
                            <span className="fw-bold">Địa điểm:</span>
                            &nbsp;
                            {item.locations && item.locations[0].districtName}
                            {item.locations && item.locations[0].districtName && <>&nbsp;-&nbsp;</>}
                            {item.locations && item.locations[0].provName}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-xl-2">
                      <p className="d-flex flex-column mt-3 mb-2">
                        <span className="text-gray-600">Thời điểm đóng thầu:</span>
                        <span className="fw-bold">{dayjs(item.bidCloseDate).format("HH:mm")}</span>
                        <span className="fw-bold">
                          {dayjs(item.bidCloseDate).format("DD/MM/YYYY")}
                        </span>
                      </p>
                      <p className="d-flex flex-column mb-2">
                        <span className="text-gray-600">Hình thức dự thầu:</span>
                        <span className="fw-bold">
                          {item.isInternet === 1 ? "Qua mạng" : "Không qua mạng"}
                        </span>
                      </p>
                    </div>
                    <div className="col-xl-2">
                      <p className="d-flex flex-column mt-3 mb-0 mb-lg-2">
                        <span className="text-gray-600">Nhà thầu trúng thầu</span>
                        <span className="fw-bold text-uppercase">Công ty CP Công nghệ Wi-MESH</span>
                      </p>
                      <p className="d-flex flex-column mb-0 mb-lg-2">
                        <span className="text-gray-600">Giá trúng thầu (VND)</span>
                        <span className="fw-bold">162.000.000</span>
                      </p>
                      <p className="d-flex flex-column mb-0 mb-lg-2">
                        <span className="text-gray-600">Ngày phê duyệt KQLCNT</span>
                        <span className="fw-bold">04/01/2024</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Drawer
        open={!!contractorIdGetDetail}
        onClose={() => setContractorIdGetDetail(null)}
        destroyOnClose
        title={contractorIdGetDetail?.bidName}
        width={900}
      >
        {contractorIdGetDetail && <ContractorDetail contractorId={contractorIdGetDetail.bidId} />}
      </Drawer>
    </div>
  );
};

export default ContractorList;
