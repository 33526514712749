import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import DashboardTemplate from "../templates/DashboardTemplate";
import ContractorPage from "../pages/PublicProcurement/ContractorPage";
import WelcomeDashboardPage from "../pages/WelcomeDashboardPage";
import ContractorTablePage from "../pages/PublicProcurement/ContractorTablePage";

const MyRouter: React.FC = () => {
  return (
    <Routes>
      <Route path="/login" Component={LoginPage} />
      <Route path="/dashboard" element={<DashboardTemplate component={WelcomeDashboardPage} />} />
      <Route
        path="/public-procurement/contractor"
        element={<DashboardTemplate title="Tìm kiếm gói thầu" component={ContractorPage} />}
      />
      <Route
        path="/public-procurement/contractor-table"
        element={
          <DashboardTemplate title="Bảng danh sách gói thầu" component={ContractorTablePage} />
        }
      />
    </Routes>
  );
};

export default MyRouter;
