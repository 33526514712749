import React from "react";
import Header from "../component/commons/Header";
import Footer from "../component/commons/Footer";
import SideBar from "../component/commons/SideBar";

interface Props {
  component: React.FC;
  title?: string;
}

const DashboardTemplate: React.FC<Props> = ({ component: Component, title }) => {
  return (
    <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
      <Header />
      <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
        <SideBar />
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
          <div className="d-flex flex-column flex-column-fluid">
            {title && (
              <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6 ">
                <div
                  id="kt_app_toolbar_container"
                  className="app-container container-fluid d-flex flex-stack "
                >
                  <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                    <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                      {title}
                    </h1>
                  </div>
                </div>
              </div>
            )}
            <div className="app-container container-fluid h-100">
              <Component />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default DashboardTemplate;
