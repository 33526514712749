import dayjs from "dayjs";
import React, { useEffect } from "react";

// CDN imported in public/index.html

interface Props {
  onChange?: (date: number) => void;
  className?: string;
  name: string;
  defaultDate?: number;
}

const DateRangePicker: React.FC<Props> = ({ className, name, defaultDate, onChange }) => {
  useEffect(() => {
    $(`input[name="date-picker-${name}"]`).daterangepicker(
      {
        singleDatePicker: true,
        autoApply: true,
        startDate: defaultDate ? dayjs(defaultDate).format("DD/MM/YYYY") : dayjs().format("DD/MM/YYYY"),
        locale: {
          format: "DD/MM/YYYY",
        },
      },
      function (date) {
        onChange && onChange(date.unix() * 1000);
      }
    );
  }, [name, onChange, defaultDate]);
  return (
    <div className="date-picker">
      <input type="text" name={`date-picker-${name}`} className={className || ""} />
    </div>
  );
};

export default DateRangePicker;
