import { Select } from "antd";
import dayjs from "dayjs";
import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ContractorType } from "../../types";
import { contractorList } from "../../data";
import DateRangePicker from "../commons/DateRangePicker";

interface Props {
  isFavoriteList: boolean;
  onSetContractorList: Dispatch<SetStateAction<ContractorType[]>>;
}

type FormDataType = {
  keyword: string;
  investField: string;
  publicDate: string;
  bidCloseDate: string;
};

const FilterContractorForm: React.FC<Props> = ({ isFavoriteList, onSetContractorList }) => {
  const submitBtnRef = useRef<HTMLButtonElement | null>(null);
  const startDateDefault = dayjs().startOf("month").format("DD/MM/YYYY HH:mm");
  const endDateDefault = dayjs()
    .month(dayjs().month() + 1)
    .startOf("month")
    .format("DD/MM/YYYY HH:mm");

  const { register, handleSubmit, setValue } = useForm<FormDataType>();

  const handleDateRangePickerChange = (
    inputName: "publicDate" | "bidCloseDate",
    start: string,
    end: string
  ) => {
    const startDate = dayjs(start).format("DD/MM/YYYY HH:mm");
    const endDate = dayjs(end).format("DD/MM/YYYY HH:mm");
    setValue(inputName, `${startDate} - ${endDate}`);
  };

  const onSubmit: SubmitHandler<FormDataType> = async (value) => {
    console.log(value, isFavoriteList);
    onSetContractorList(contractorList);
  };

  useEffect(() => {
    register("investField", { value: "HH, XL, PTV, TV, HON_HOP" });
    register("publicDate", { value: `${startDateDefault} - ${endDateDefault}` });
    register("bidCloseDate", { value: `${startDateDefault} - ${endDateDefault}` });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (submitBtnRef.current) {
      submitBtnRef.current.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFavoriteList]);

  return (
    <div className="card mb-5">
      <div className="card-header border-0 min-h-50px">
        <div className="card-title m-0">
          <h3 className="fw-bold m-0">Tìm kiếm</h3>
        </div>
      </div>
      <div className="collapse show">
        <form
          className="form fv-plugins-bootstrap5 fv-plugins-framework"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <div className="card-body border-top px-9 py-2">
            <div className="row gx-10">
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label className="col-form-label col-form-label-sm fw-semibold fs-6x text-end">
                    Từ khoá
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm bg-transparent"
                    placeholder="Từ khoá"
                    {...register("keyword")}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label className="col-form-label col-form-label-sm fw-semibold fs-6x text-end">
                    Lĩnh vực
                  </label>
                  <Select
                    defaultValue="HH, XL, PTV, TV, HON_HOP"
                    onChange={(value) => setValue("investField", value)}
                    className="form-control form-control-sm"
                    options={[
                      { value: "HH, XL, PTV, TV, HON_HOP", label: "Tất cả" },
                      { value: "HH", label: "Hàng hoá" },
                      { value: "XL", label: "Xây lắp" },
                      { value: "PTV", label: "Phi tư vấn" },
                      { value: "TV", label: "Tư vấn" },
                      { value: "HON_HOP", label: "Hỗn hợp" },
                    ]}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label className="col-form-label col-form-label-sm fw-semibold fs-6x text-end">
                    Thời gian đăng tải
                  </label>
                  <DateRangePicker
                    name="publicDate"
                    className="form-control form-control-sm bg-transparent"
                    onChange={(start, end) => handleDateRangePickerChange("publicDate", start, end)}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label className="col-form-label col-form-label-sm fw-semibold fs-6x text-end">
                    Thời gian đóng thầu
                  </label>
                  <DateRangePicker
                    name="bidCloseDate"
                    className="form-control form-control-sm bg-transparent"
                    onChange={(start, end) =>
                      handleDateRangePickerChange("bidCloseDate", start, end)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer d-flex justify-content-end py-4 px-9">
            <button
              type="submit"
              className="btn btn-primary"
              id="kt_account_profile_details_submit"
              ref={submitBtnRef}
            >
              Tìm kiếm
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FilterContractorForm;
