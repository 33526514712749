const nameKey = {
  theme: "wm-theme",
  token: "wm-report-token",
};

const regex = {
  email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  phone: /^(84|0|\+84)(3|5|7|9)[0-9]{8}$/,
};

export { nameKey, regex };
