import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { regex } from "../utils/constant";

type LoginType = {
  email: string;
  password: string;
};

const LoginPage: React.FC = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginType>();

  const onSubmit: SubmitHandler<LoginType> = async (value) => {
    console.log(value);
  };

  return (
    <div className="login-page d-flex align-items-center" style={{ minHeight: "100vh" }}>
      <div className="w-lg-500px p-10 mx-auto">
        <div className="text-center mb-10">
          <img src="/assets/images/logo.png" alt="logo-wimesh" />
        </div>
        <form
          className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
          noValidate={true}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="text-center mb-11">
            <h1 className="text-gray-900 fw-bolder mb-3">Đăng nhập</h1>
            <div className="text-gray-500 fw-semibold fs-6">Your Social Campaigns</div>
          </div>
          <div className="fv-row mb-8 fv-plugins-icon-container">
            <input
              type="text"
              placeholder="Email"
              className="form-control bg-transparent"
              {...register("email", {
                required: "Vui lòng nhập Email!",
                pattern: {
                  value: regex.email,
                  message: "Email không hợp lệ!",
                },
              })}
            />
            {errors.email && (
              <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                {errors.email.message}
              </div>
            )}
          </div>
          <div className="fv-row mb-8 fv-plugins-icon-container">
            <input
              type="password"
              placeholder="Mật khẩu"
              className="form-control bg-transparent"
              {...register("password", {
                required: "Vui lòng nhập Mật khẩu!",
              })}
            />
            {errors.password && (
              <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback d-block text-danger">
                {errors.password.message}
              </div>
            )}
          </div>
          <div className="d-grid mb-10">
            <button type="submit" className="btn btn-primary">
              <span className="indicator-label">Đăng nhập</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
