import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../NavBar";

const Header: React.FC = () => {
  const handleToggleSidebar = () => {
    // show sidebar in mobile
    const sidebarEle = document.querySelector("#kt_app_sidebar");
    const bodyEle = document.querySelector("body");
    sidebarEle?.classList.add("drawer-on");

    // add overlay element, when click ovelay will hide sidebar
    const handleHideSideBar = () => {
      sidebarEle?.classList.remove("drawer-on");
      overlayNode.removeEventListener("click", handleHideSideBar);
      bodyEle?.removeChild(overlayNode);
    };
    const overlayNode = document.createElement("div");
    overlayNode.classList.add("drawer-overlay");
    overlayNode.style.zIndex = "105";
    overlayNode.addEventListener("click", handleHideSideBar);

    bodyEle?.appendChild(overlayNode);
  };

  return (
    <div id="kt_app_header" className="app-header">
      <div
        className="app-container container-fluid d-flex align-items-stretch justify-content-between"
        id="kt_app_header_container"
      >
        <div
          className="d-flex align-items-center d-lg-none ms-n3 me-1 me-md-2"
          title="Show sidebar menu"
        >
          <div
            className="btn btn-icon btn-active-color-primary w-35px h-35px"
            id="kt_app_sidebar_mobile_toggle"
            onClick={handleToggleSidebar}
          >
            <span className="svg-icon svg-icon-2 svg-icon-md-1">
              <img src="/assets/icons/menu-2.svg" alt="" />
            </span>
          </div>
        </div>

        <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
          <Link to="/" className="d-lg-none">
            <img alt="Logo" src="/assets/images/logo.png" className="h-30px" />
          </Link>
        </div>

        <div
          className="d-flex align-items-stretch justify-content-end flex-lg-grow-1"
          id="kt_app_header_wrapper"
        >
          <NavBar />
        </div>
      </div>
    </div>
  );
};

export default Header;
