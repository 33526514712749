import { Empty, Select, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { areas, contractorList } from "../../data";
import DatePicker from "../../component/commons/DatePicker";
import { formatPriceToVND } from "../../utils";
import dayjs from "dayjs";
import { ContractorType } from "../../types";

const ContractorTablePage: React.FC = () => {
  const [contractors, setContractors] = useState<ContractorType[]>([]);
  const [filteredContractors, setFilteredContractors] = useState<ContractorType[]>([]);
  const [formValues, setFormValues] = useState({
    keyword: "",
    investField: "HH, XL, PTV, TV, HON_HOP",
    area: "all",
    closeBidDate: dayjs().unix() * 1000,
  });

  const filterAreaOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleChangeFormValues = (key: "keyword" | "investField" | "area" | "closeBidDate", value: string | number) => {
    setFormValues((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    setContractors(contractorList);
  }, []);

  useEffect(() => {
    setFilteredContractors(
      contractors.filter((item) => {
        let result = false;
        // Check keyword filed
        if (formValues.keyword !== "") {
          result =
            item.notifyNo.toLowerCase().includes(formValues.keyword.toLowerCase()) ||
            item.bidName[item.bidName.length - 1].toLowerCase().includes(formValues.keyword.toLowerCase()) ||
            item.investorName[item.investorName.length - 1].toLowerCase().includes(formValues.keyword.toLowerCase());
        } else {
          result = true;
        }
        // Check invest field
        result = formValues.investField.split(", ").some((i) => {
          return item.investField.includes(i);
        });
        // Check area
        if (formValues.area === "all") {
          result = true;
        } else {
          result = item.locations ? item.locations[item.locations.length - 1].provCode === formValues.area : false;
        }
        // Check bid close date
        result = dayjs(item.bidCloseDate).unix() * 1000 >= formValues.closeBidDate;
        return result;
      })
    );
  }, [contractors, formValues]);

  return (
    <div className="contractor-table-page">
      <div className="card mb-5">
        <div className="card-body">
          {/* Filter form */}
          <div className="d-flex gap-5 flex-wrap mb-5">
            <div className="w-150px">
              <label className="col-form-label col-form-label-sm fw-semibold fs-6x text-end pt-0 pb-1">Từ khoá</label>
              <input
                type="text"
                value={formValues.keyword}
                onChange={(e) => handleChangeFormValues("keyword", e.target.value)}
                className="form-control form-control-sm bg-transparent"
                placeholder="Từ khoá"
              />
            </div>
            <div className="w-125px">
              <label className="col-form-label col-form-label-sm fw-semibold fs-6x text-end pt-0 pb-1">Lĩnh vực</label>
              <Select
                defaultValue={formValues.investField}
                onChange={(value) => handleChangeFormValues("investField", value)}
                className="form-control form-control-sm"
                options={[
                  { value: "HH, XL, PTV, TV, HON_HOP", label: "Tất cả" },
                  { value: "HH", label: "Hàng hoá" },
                  { value: "XL", label: "Xây lắp" },
                  { value: "PTV", label: "Phi tư vấn" },
                  { value: "TV", label: "Tư vấn" },
                  { value: "HON_HOP", label: "Hỗn hợp" },
                ]}
              />
            </div>
            <div className="w-200px">
              <label className="col-form-label col-form-label-sm fw-semibold fs-6x text-end pt-0 pb-1">Địa điểm</label>
              <Select
                showSearch
                defaultValue={formValues.area}
                onChange={(value) => handleChangeFormValues("area", value)}
                className="form-control form-control-sm"
                optionFilterProp="children"
                filterOption={filterAreaOption}
                options={[
                  { value: "all", label: "Tất cả" },
                  ...areas.map((item) => {
                    return {
                      value: item.provCode,
                      label: item.provName,
                    };
                  }),
                ]}
              />
            </div>
            <div className="w-150px">
              <label className="col-form-label col-form-label-sm fw-semibold fs-6x text-end pt-0 pb-1">Ngày đóng thầu</label>
              <DatePicker
                name="closeBidDate"
                className="form-control form-control-sm bg-transparent"
                defaultDate={formValues.closeBidDate}
                onChange={(date) => handleChangeFormValues("closeBidDate", date)}
              />
            </div>
          </div>
          {/* Filtered Contractor Table */}
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr className="fw-bold fs-6 text-gray-800">
                  <th className="text-center min-w-125px mw-125px position-sticky bg-secondary border-0" style={{ left: 0 }}>
                    Mã TBMT
                  </th>
                  <th className="text-center">Tên gói thầu</th>
                  <th className="text-center">Giá thầu</th>
                  <th className="text-center">Lĩnh vực</th>
                  <th className="text-center">Chủ đầu tư</th>
                  <th className="text-center">Địa điểm</th>
                  <th className="text-center">Ngày đăng</th>
                  <th className="text-center">Ngày đóng</th>
                  <th className="text-center">Chi tiết</th>
                </tr>
              </thead>
              <tbody>
                {filteredContractors.length === 0 ? (
                  <tr>
                    <td colSpan={9}>
                      <Empty description="Không có dữ liệu" />
                    </td>
                  </tr>
                ) : (
                  filteredContractors.map((item) => {
                    return (
                      <tr key={item.Id}>
                        <td className="text-truncate text-center min-w-125px mw-125px position-sticky bg-secondary border-0" style={{ left: 0 }}>
                          {item.notifyNo}
                        </td>
                        <td className="text-truncate min-w-300px mw-300px">
                          <Tooltip placement="topLeft" title={item.bidName}>
                            {item.bidName}
                          </Tooltip>
                        </td>
                        <td className="text-truncate text-center">{formatPriceToVND(item.bidPrice[item.bidPrice.length - 1])}</td>
                        <td className="text-truncate min-w-80px text-center">{item.investField.join(", ")}</td>
                        <td className="text-truncate mw-200px">
                          <Tooltip placement="topLeft" title={item.investorName}>
                            {item.investorName}
                          </Tooltip>
                        </td>
                        <td className="text-truncate">{item.locations && item.locations[item.locations.length - 1].provName}</td>
                        <td className="text-truncate">{dayjs(item.publicDate).format("HH:mm - DD/MM/YYYY")}</td>
                        <td className="text-truncate">{dayjs(item.bidCloseDate).format("HH:mm - DD/MM/YYYY")}</td>
                        <td className="text-truncate text-center min-w-70px">link</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractorTablePage;
