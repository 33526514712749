import React, { useState } from "react";
import FilterContractorForm from "../../component/contractor/FilterContractorForm";
import ContractorList from "../../component/contractor/ContractorList";
import { ContractorType } from "../../types";

const ContractorPage: React.FC = () => {
  const [isFavoriteList, setIsFavoriteList] = useState(false);
  const [contractorList, setContractorList] = useState<ContractorType[]>([]);
  return (
    <div className="contractor-page">
      <FilterContractorForm
        isFavoriteList={isFavoriteList}
        onSetContractorList={setContractorList}
      />
      <div className="card mb-5">
        <div className="card-body pt-0 pb-0">
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-6 fw-bold">
            <li className="nav-item mt-2">
              <span
                className={`nav-link text-active-primary cursor-pointer ms-0 me-10 py-3 ${
                  !isFavoriteList ? "active" : ""
                }`}
                onClick={() => setIsFavoriteList(false)}
              >
                Tất cả
              </span>
            </li>
            <li className="nav-item mt-2">
              <span
                className={`nav-link text-active-primary cursor-pointer ms-0 me-10 py-3 ${
                  isFavoriteList ? "active" : ""
                }`}
                onClick={() => setIsFavoriteList(true)}
              >
                Quan tâm
              </span>
            </li>
          </ul>
        </div>
      </div>
      <ContractorList contractorList={contractorList} />
    </div>
  );
};

export default ContractorPage;
