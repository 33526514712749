const contractorList = [
  {
    _id: {
      $oid: "658ba94cd6c964da1a8cbd71",
    },
    Id: 1,
    bidCloseDate: "2024-01-08T09:00:00",
    bidForm: "DTRR",
    bidId: "d803e6c6-5d18-48bd-ab83-9e290998ab26",
    bidMode: "1_MTHS",
    bidName: ["Gói thầu: Bảo dưỡng trang thiết bị y tế tại Bệnh viện đa khoa huyện Quốc Oai"],
    bidOpenDate: "2024-01-08T09:00:00",
    bidPrice: [274000000],
    createdBy: "vn0103572640",
    id: "20161e26-164d-4ec8-b359-c63f7ab8ca88",
    investField: ["PTV"],
    investorName: "Bệnh viện Đa khoa huyện Quốc Oai",
    isInternet: 1,
    locations: [
      {
        provCode: "101",
        provName: "Thành phố Hà Nội",
        districtCode: "10139",
        districtName: "Huyện Quốc Oai",
      },
    ],
    notifyId: "20161e26-164d-4ec8-b359-c63f7ab8ca88",
    notifyNo: "IB2300385814",
    notifyNoStand: "IB2300385814-00",
    notifyVersion: "00",
    numBidderTech: 0,
    numClarifyReq: 0,
    numPetition: 0,
    numPetitionHsmt: 0,
    numPetitionKqlcnt: 0,
    numPetitionLcnt: 0,
    planNo: "PL2300261253",
    planType: "TX",
    processApply: "LDT",
    procuringEntityCode: "vn0103572640",
    procuringEntityName: "Bệnh viện Đa khoa huyện Quốc Oai",
    publicDate: "2023-12-27T10:38:54.509",
    score: "NaN",
    status: "01",
    statusForNotify: "",
    stepCode: "notify-contractor-step-1-tbmt",
    type: "es-notify-contractor",
    UpdateAt: "",
    CreateAt: {
      $numberLong: "1703651660253",
    },
  },
  {
    _id: {
      $oid: "658ba94cd6c964da1a8cbd73",
    },
    Id: 2,
    bidCloseDate: "2024-01-02T09:30:00",
    bidForm: "CHCT",
    bidId: "65ef5a16-7f8c-4b18-be80-bd936f802b33",
    bidMode: "1_MTHS",
    bidName: [
      "DV-015/24-VP : Tổ chức tổ chức Hội thảo trao đổi về các vấn đề thực hiện Nghị quyết kỳ họp 56 Hội đồng Vietsovpetro (huynq.hq-2448)",
    ],
    bidOpenDate: "2024-01-02T09:30:00",
    bidPrice: [1792657350],
    createdBy: "vn3500102414-12",
    id: "13374d6d-7b07-4952-97ac-d376cb3abaaa",
    investField: ["PTV"],
    investorName: "Liên doanh Việt-Nga Vietsovpetro",
    isInternet: 1,
    locations: [
      {
        provCode: "717",
        provName: "Tỉnh Bà Rịa - Vũng Tàu",
        districtCode: "71701",
        districtName: "Thành phố Vũng Tàu",
      },
    ],
    notifyId: "13374d6d-7b07-4952-97ac-d376cb3abaaa",
    notifyNo: "IB2300386187",
    notifyNoStand: "IB2300386187-00",
    notifyVersion: "00",
    numBidderTech: 0,
    numClarifyReq: 0,
    numPetition: 0,
    numPetitionHsmt: 0,
    numPetitionKqlcnt: 0,
    numPetitionLcnt: 0,
    planNo: "PL2300266806",
    planType: "TX",
    processApply: "KHAC",
    procuringEntityCode: "vn3500102414",
    procuringEntityName: "Liên doanh Việt-Nga Vietsovpetro",
    publicDate: "2023-12-27T10:40:30.546",
    score: "NaN",
    status: "01",
    statusForNotify: "",
    stepCode: "notify-contractor-step-1-tbmt",
    type: "es-notify-contractor",
    UpdateAt: "",
    CreateAt: {
      $numberLong: "1703651660438",
    },
  },
];

export default contractorList;
