import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import "./index.scss";
import ModalChangePassword from "../ModalChangePassword";

const NavBar: React.FC = () => {
  const [isChangePassword, setIsChanegPassword] = useState(false);

  return (
    <Fragment>
      <div className="app-navbar flex-shrink-0">
        <div className="app-navbar-item ms-1 ms-md-3" id="kt_header_user_menu_toggle">
          <div
            className="cursor-pointer symbol symbol-30px symbol-md-40px"
            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            id="kt_header_user_menu_toggle_avatar"
          >
            <img src="/assets/images/avatar.jpeg" alt="account" />
          </div>

          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
            data-kt-menu="true"
            id="kt_header_user_menu_toggle_menu"
          >
            <div className="menu-item px-3">
              <div className="menu-content d-flex align-items-center px-3">
                <div className="symbol symbol-50px me-4">
                  <img alt="Logo" src="/assets/images/avatar.jpeg" />
                </div>
                <div className="d-flex flex-column w-150px">
                  <div className="fw-bold fs-5 text-overflow text-overflow-1 text-truncate">
                    liempt@wi-mesh.vn
                  </div>
                </div>
              </div>
            </div>
            <div className="separator my-2"></div>

            <div className="menu-item px-4 my-1">
              <span onClick={() => setIsChanegPassword(true)} className="menu-link px-4">
                Thay đổi mật khẩu
              </span>
            </div>

            <div className="menu-item px-4">
              <NavLink to="/" className="menu-link px-4 text-decoration-none">
                Đăng xuất
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <ModalChangePassword isOpen={isChangePassword} onClose={() => setIsChanegPassword(false)} />
    </Fragment>
  );
};

export default NavBar;
